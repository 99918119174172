import { Badge } from "@/components/ui/badge"
import { classes } from "../utils/util"

export function Interlude({
  badgeCopy,
  title,
  description,
  className,
}: {
  badgeCopy: string
  title: string
  description: string
  className?: string
}) {
  return (
    <div className={classes("bg-sapphire-100 flex flex-col items-center justify-center px-4 text-sapphire-900", className)}>
      <Badge variant="secondary" className="mb-8">
        {badgeCopy}
      </Badge>

      <h1 className="text-5xl font-bold text-center mb-4">{title}</h1>

      <p className="text-xl text-center text-sapphire-700 mb-16 max-w-2xl">{description}</p>
    </div>
  )
}

export function Footer() {
  return (
    <div class="justify-center max-w-xl mx-auto sm:py-20">
      <div className="border sm:rounded-2xl border-sapphire-600 bg-sapphire-400 bg-opacity-70">
        <div className="flex items-center justify-center p-2">
          <a href="/" className="flex items-center space-x-3">
          <img
      class={`block h-24 mr-2`}
      src="/img/logo.png"
      alt="Dr. Drip"
    />
          </a>
        </div>
        <div class="flex flex-col items-center justify-center my-2">
        <p className="text-sapphire-900 mb-4">Rivervale, NJ 07675</p>
          <p className="text-sapphire-900">NJMP# 36BI01371200</p>
          <p className="text-sapphire-900 my-4">NJHIC# 13VH13069000</p>
          <footer className="text-sapphire-900">{`© Dr. Drip ${new Date().getUTCFullYear()}. All rights reserved.`}</footer>
        </div>
      </div>
    </div>
  )
}
