import type { JSX } from "preact"
import { useEffect } from "preact/hooks"
import { useSearch } from "wouter-preact/use-location"

export function classes(...args: (string | JSX.SignalLike<string | undefined> | undefined)[]) {
  return args.filter(Boolean).join(" ")
}

export function useAsyncEffect(effect: () => Promise<any>, deps: any[] = []) {
  return useEffect(() => safeExec(effect), deps)
}

export function safeExec(action: () => Promise<any>) {
  action().catch(e => {
    // captureException(e)
    console.log(e)
  })
}

export function useSearchParams<T extends object>(): T {
  let search_string: string = useSearch()
  let search_params = new URLSearchParams(search_string)
  return Object.fromEntries(search_params.entries()) as T
}

type UpdateParam = { key: string; value: string };
type Params = { [key: string]: string | number | undefined };

export function updateSearchParams(params: Params, update_params: UpdateParam[]) {
  let qs = new URLSearchParams();
  const updateParamMap = new Map(update_params.map(param => [param.key, param.value]));
  
  // First, set all existing params
  for (let [k, v] of Object.entries(params)) {
    if (v !== undefined) {
      qs.set(k, v.toString());
    }
  }

  // Then, update or add new params
  for (let [k, v] of updateParamMap) {
    qs.set(k, v);
  }

  return qs;
}


