import { Card } from "@/components/ui/card.tsx"
import { Interlude } from "./util"

export function Team() {
  const team = [
    {
      name: "Nick Lepinski",
      title: "Founder",
      profile_picture: "/img/nick_ana_s.jpg", 
      linkedin: "",
      logos: [],
      background: "As a lifelong NJ resident and master plumber with over 10 years of service experience in NJ, Nick takes pride in keeping the water and air of NJ families' homes running smoothly.",
    },
    {
      name: "Christian",
      title: "Super apprentice",
      profile_picture: "/img/christian3.jpeg", 
      linkedin: "",
      logos: [],
      background: "Christian is a third year super apprentice who can fix anything (in English and Spanish!). When he is not working on plumbing systems he enjoys learning about all things automotive and also closely follows Motorsports and Football ",
    },
  ]
  return (
    <div className="bg-sapphire-50 pt-24">

    <div class="pb-24 bg-sapphire-100" id="team">
      <div class="pt-12">
      <Interlude
        badgeCopy="Company"
        title="Our team"
        description="Family-run and veteran-owned company on a mission to bring comfort to your family's home."
      />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 max-w-7xl mx-auto ">
        {team.map((member, index) => (
          <Card key={index} className="flex flex-col p-4 bg-sapphire-50 shadow-lg rounded-lg">
            <div className="flex items-center justify-center w-full mb-4">
              <div className="flex items-center">
                <div className="w-48 h-48 rounded-full overflow-hidden">
                  <img
                    src={member.profile_picture}
                    alt={member.name + " profile picture"}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ml-4">
                  <p className="text-sapphire-900 font-bold text-lg">{member.name}</p>
                  <p className="text-sapphire-700">{member.title}</p>
                </div>
              </div>
              {/* <a href={member.linkedin} className="ml-4" target="_blank" rel="noopener noreferrer">
                <img src="/img/linkedin.svg" alt="LinkedIn" className="w-6 h-6" />
              </a> */}
            </div>
            <p className="text-sapphire-700 mt-2 text-center">{member.background}</p>
          </Card>
        ))}
      </div>
    </div>
    </div>

  )
}
