import {Route, Switch} from "wouter-preact"
import {Landing} from "src/landing/landing.tsx"


export function Routes() {
  return (
    <Switch>
      <Route path="/" component={Landing}/>
    </Switch>
  )
}
