import { PhoneCallIcon } from "lucide-react";

export function Logo({h = 12, w = 12}: {h?: number , w?: number}) {
  return <div class="flex flex-shrink-0 items-center mx-auto">
    <img
      class={`block h-${h} w-${w} mr-2`}
      src="/img/logo.png"
      alt="Dr. Drip Plumbing Logo"
    />
  </div>
}
