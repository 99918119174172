import { useState } from "preact/hooks";
import { PHONE_NUMBER } from "./hero";

export function Banner() {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  return isBannerVisible ? (
    <div
      id="banner"
      tabIndex={-1}
      className="flex items-center z-50 h-12 gap-8 justify-center py-1 px-4 w-full bg-yellow-200 border border-b border-sapphire-200 sm:items-center"
    >
      <p className="text-sm text-yellow-800 text-center">
        24/7 emergency service, call <a href={`tel:+1${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
      </p>
      <button
        onClick={() => setIsBannerVisible(false)}
        type="button"
        className="fixed right-2 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  ) : (
    <></>
  );
}
